<template>
  <div class="full-member-background">
    <b-container fluid>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </b-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Content from './Content'

export default {
  name: 'general-screen-dashboard',
  components: {
    Navbar,
    Content
  }
}
</script>
